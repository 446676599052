
.custom-content {
    margin-top: 18px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    padding-bottom: 52px;

    .custom-wrap {
        margin: 0 20px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;

        .item-content {
            display: flex;
            align-items: center;
            justify-content: center;

            .divide {
                width: 20px;
                height: 2px;
                background: #D2D2D2;
                margin: 0 10px;
            }
        }

        .input-text {
            margin: 0 10px;

            &.sex {
                margin: 0 17px;
            }

            &.front {
                margin: 0 12px 0 22px;
            }
        }

        .item-input {
            &.medium {
                width: 500px;
            }

            &.mini {
                width: 200px;
            }

            &.sex {
                width: 184px;
            }

            &.tiny {
                width: 120px;
            }
        }

        .page-button {
            text-align: center;
        }
    }
}
